import React, {useCallback, useState} from 'react';
import './ComingSoon.css';
import LogoLarge from '../../../assets/logo-large.png';
import {toast} from 'react-toastify';
import {Container, Image} from "react-bootstrap";

const ComingSoon = () => {
    const [email, setEmail] = useState<string>('');
    const onChangeEmail = useCallback<React.ChangeEventHandler<HTMLInputElement>>(({target: {value}}) => {
        setEmail(value);
    }, []);
    const onSubmit = useCallback(() => {
        toast.promise(fetch('https://api.tycoonland.nyc/waitlist', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
            })
        }), {
            pending: "Submitting...",
            success: "Success! We'll keep you updated!",
            error: "Something went wrong...",
        })
            .then(res => {
                console.log("res: ", res);
                return res.json()
            })
            .then(res => {
                console.log("Json", res);
            })
            .catch(err => console.log(err))
            .finally(() => {
                setEmail('');
            })
    }, [email]);
    return (
        <Container>
            <div className="image-container">
                <Image alt="Tycoonland Logo" src={LogoLarge} className="logo" />
                <p style={{color: 'black', textAlign: "center"}}>Play, compete, and invest on Manhattan’s digital free-market real estate brokerage platform!</p>
            </div>
            <div className="text-container">
                <h3>Coming Soon...</h3>
                <p style={{margin: 0}}>Tycoonland is currently under construction.</p>
                <p>Please leave us your email address to be notified upon ribbon cutting release of software!</p>
                <div className="input-container">
                    <p style={{margin: 0}}>Email:</p>
                    <input value={email} onChange={onChangeEmail} placeholder="sample@email.com" type="email" className="email-input" />
                    <div className="button-container">
                        <a style={{margin: 0, textDecorationColor: 'gray'}} href="mailto:support@tycoonland.nyc">
                            <p style={{margin: 0, color: "gray"}}>Contact Support</p>
                        </a>
                        <button onClick={onSubmit} className="submit-button">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default ComingSoon;
