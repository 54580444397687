import React from 'react';
import './App.css';
import ComingSoon from "./routes/home/ComingSoon";

function App() {
  return (
      <div className="main-container">
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, flexDirection: 'column'}}>
          <ComingSoon />
        </div>
      </div>
  );
}

export default App;
